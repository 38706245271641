import { PURGE } from "redux-persist";

let initialState = {
  isLogin: localStorage.getItem("token") ? true : false,
  setLoader: { message: 'Please Wait...', status: false },
  getVerifyCode: false,
  getUserHaveEmail: false,
  getUserForget: false,
  getRegisterUser:{},
  mediaItems : [],
  setVoting: [],
  setSingleVoting: {votings:{},votingItems:[]},
  saleItems: [],
  registerEmail: null,
  saleItemById: {},
  userData:{},
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'SET_LOGIN':
      return {
        ...state,
        isLogin: payload,
      };

    case 'LOGOUT':
      localStorage.removeItem('token');
      // window.location.href = '/'
      return {
        ...state,
        isLogin: false,
      };

    case 'SET_USER_PROFILE':
      return {
        ...state,
        userData:payload
    }

    case 'SET_REGISTER_EMAIL':
      return {
        ...state,
        registerEmail: payload
      }

    case 'TOGGLE_LOADER':
      return {
        ...state,
        setLoader: payload,
      };

    case 'GET_REGISTER_USER':
      return {
        ...state,
        getRegisterUser: payload
      }
    
    case 'GET_VERIFY_CODE':
      return {
        ...state,
        getVerifyCode: payload
      }

      case 'GET_USER_EMAIL':
        return {
          ...state,
          getUserHaveEmail: payload
        }
      
      case 'GET_USER_FORGET':
        return {
          ...state,
          getUserForget: payload
        }

    /*========== MEDIA REDUCERS ============= */

    case 'SET_MEDIA_ITEMS':
      return {
        ...state,
        mediaItems: payload
      };

    case 'SET_VOTING':
      return {
        ...state,
        setVoting:payload
      };

    case 'SET_SINGLE_VOTING':
      return {
        ...state,
        setSingleVoting:payload
      }
    /*========== SALE REDUCERS ============= */

    case 'SET_SALE_ITEMS':
      return {
        ...state,
        saleItems: payload
      }; 

    case 'SET_SALE_ITEMS_BY_ID':
      return {
        ...state,
        saleItemById: payload
      }

    default:
      return state;
  }
};

export default Auth;