import axios from 'axios';
import EventBus from 'eventing-bus';
import jwt_decode from 'jwt-decode';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import { setToken } from "../axios";
import {
  toggleLoader, setMediaItems, setVotings, setSingleVotings, setSaleItems, setLogin,
  getRegisterUser, getVerifyCode, getUserHaveEmail, getUserForget, setSaleItemsById, setUserProfile
} from '../actions/Auth';


export function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/users/loginUser', payload });
  if (error) {
    yield put(toggleLoader({ status: false }))
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    localStorage.setItem('token', response["data"]["body"]["token"]);
    setToken(response["data"]["body"]["token"]);
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "user") {
      message.error({ type: 'error', content: "Can't login through User account " });
      return;
    }
    yield put(toggleLoader({ status: false }));
    yield put(setLogin(true));
    EventBus.publish('success', response['data']['message']);
  }
};

export function* setRegisterUser({ payload }) {
  const { error, response } = yield call(postCall, { path: "/users/registerUser", payload });
  if (error) {
    yield put(toggleLoader({ status: false }))
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(toggleLoader({ status: false }))
    yield put(getRegisterUser(response['data']['body']))
    EventBus.publish('success', response['data']['message']);
  }
};


export function* setVerifyCode({ payload }) {
  const { error, response } = yield call(postCall, { path: "/users/codeVerify", payload });
  if (error) {
    // yield put(getVerifyCode(response['data']['body']['status']))
    yield put(toggleLoader({ status: false }))
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(toggleLoader({ status: false }))
    yield put(getVerifyCode(response['data']['body']['status']))
    EventBus.publish('success', response['data']['message']);
  }
};

export function* setUserEmail({ payload }) {
  const { error, response } = yield call(postCall, { path: "/users/checkEmail", payload });
  if (error) {
    // yield put(getUserHaveEmail(response['data']['body']['status']))
    yield put(toggleLoader({ status: false }))
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(getUserHaveEmail(response['data']['body']['status']))
    yield put(toggleLoader({ status: false }))
    EventBus.publish('success', response['data']['message']);
  }
};

export function* setUserForget({ payload }) {
  const { error, response } = yield call(postCall, { path: "/users/forgot", payload });
  if (error) {
    // yield put(getUserForget(response['data']['body']['status']))
    yield put(toggleLoader({ status: false }))
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(getUserForget(response['data']['body']['status']))
    yield put(toggleLoader({ status: false }))
    EventBus.publish('success', response['data']['message']);
  }
};

function* innerFormData({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/info', payload });
  if (error) EventBus.publish('error', error['response']['data']['message'])
  else if (response) EventBus.publish('success', response['data']['message']);
};

export function* getUserProfile() {
  const { error, response } = yield call(getCall, "/users/getUserProfile");
  if (error) {
    // EventBus.publish("error", error['response']['data']['message']); 
  }
  else if (response) {
    yield put(setUserProfile(response['data']['body']));
  }
  yield put(toggleLoader({ status: false }))
};

export function* updateProfile({ payload }) {
  console.log("******* payload", payload);
  const { error, response } = yield call(postCall, { path: '/users/updateProfile', payload });
  console.log("******* error", error);
  console.log("******* response", response);
  if (error) {
    EventBus.publish("error", error['response']['data']['message']);
    yield put(toggleLoader({ status: false }))
  }
  else if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put(toggleLoader(false));
    yield put({
      type: "GET_USER_PROFILE",
    });
  }
  yield put(toggleLoader({ status: false }))
};

/*========== MEDIA FUNCTIONS =============*/

export function* getMediaItems() {
  const { error, response } = yield call(getCall, "/users/getMediaItems");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setMediaItems(response['data']['body']));
  }
  yield put(toggleLoader({ status: false }))
};

export function* getVotings() {
  const { error, response } = yield call(getCall, "/users/getVotings");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setVotings(response['data']['body']));
  }
  yield put(toggleLoader({ status: false }))
};


export function* getSingleVotings({ payload }) {
  const { error, response } = yield call(getCall, `/users/getVotingsItems/${payload['id']}`);
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setSingleVotings(response['data']['body']));
  }
  yield put(toggleLoader({ status: false }))
};

export function* addVote({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/addVote', payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setSingleVotings(response['data']['body']));
  }
  yield put(toggleLoader({ status: false }))
}

/*========== SALE FUNCTIONS =============*/

export function* getSaleItems() {
  const { error, response } = yield call(getCall, "/users/getSaleItems");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setSaleItems(response['data']['body']));
  }
  yield put(toggleLoader({ status: false }))
};

export function* getSaleItemsById({ payload }) {
  const { error, response } = yield call(getCall, "/users/getSaleItemsById/" + payload["saleId"]);
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setSaleItemsById(response['data']['body']));
  }
  yield put(toggleLoader({ status: false }))
};

export function* buyItem({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/payment', payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    EventBus.publish('success', response['data']['message']);
  }
  yield put(toggleLoader({ status: false }))
};

export function* itemSold({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/itemSold', payload });
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    EventBus.publish('success', response['data']['message']);
  }
  yield put(toggleLoader({ status: false }))
};


function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('SET_REGISTER_USER', setRegisterUser);
  yield takeEvery('SET_VERIFY_CODE', setVerifyCode);
  yield takeEvery('SET_USER_EMAIL', setUserEmail);
  yield takeEvery('SET_USER_FORGET', setUserForget);
  yield takeEvery('GET_USER_PROFILE', getUserProfile);
  yield takeEvery('UPDATE_PROFILE', updateProfile);


  yield takeEvery('ADD_VOTE', addVote);
  yield takeEvery('GET_SINGLE_VOTING', getSingleVotings);
  yield takeEvery('GET_VOTING', getVotings);
  yield takeEvery('INNER_FORM', innerFormData);
  yield takeEvery('GET_MEDIA_ITEMS', getMediaItems);

  yield takeEvery('GET_SALE_ITEMS', getSaleItems);
  yield takeEvery('GET_SALE_ITEMS_BY_ID', getSaleItemsById);
  yield takeEvery('SET_BUY_ITEM', buyItem);
  yield takeEvery('ITEM_SOLD', itemSold);

};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
