import { connect } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import EventBus from "eventing-bus";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './index.css';
import '../../static/css/animate.css';
import 'animate.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getSaleItemsById, buyItem, itemSold } from "../../store/actions/Auth";

const stripePromise = loadStripe(process.env.REACT_APP_API_KEY);

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "black",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "black" },
            "::placeholder": { color: "black" }
        },
        invalid: {
            iconColor: "#ffc7ee",
            color: "black"
        }
    }
}

const PaymentMethod = (props) => {

    const stripe = useStripe()
    const elements = useElements()

    const { saleId } = useParams();

    let [saleData, setSaleData] = useState([]);
    let [salePrice, setSalePrice] = useState(0);
    let [selectedCurrency, setSelectedCurrency] = useState("USD");

    useEffect(() => {
        props.toggleLoader({ message: "Load item...", status: true });
        props.getSaleItemsById({ saleId });
    }, []);

    useEffect(() => {
        if (props.saleItemById && Object.keys(props.saleItemById).length > 0) {
            setSaleData(props.saleItemById);
            setSalePrice(props.saleItemById.price);
        }
    }, [props.saleItemById]);

    const selectCurrency = async (currency) => {
        if (currency == "HKD") {
            try {
                props.toggleLoader({ message: "Convert rate...", status: true });
                setSelectedCurrency(currency);
                const response = await fetch('https://v6.exchangerate-api.com/v6/6e88e47200ff74f65b782080/pair/USD/HKD'); // Replace with your API endpoint
                if (!response.ok) {
                    return EventBus.publish("error", error['response']['data']['message']);
                }

                const result = await response.json();
                let price = Number(props.saleItemById.price) * Number(result.conversion_rate)
                setSalePrice(price);
                props.toggleLoader({ message: "Convert rate...", status: false });
            } catch (err) {
                setSelectedCurrency("USD");
                props.toggleLoader({ message: "Convert rate...", status: false });
            }
        } else {
            setSelectedCurrency(currency);
            setSalePrice(props.saleItemById.price);
        }
    }

    const handlePayment = async () => {
        try {
            props.toggleLoader({ message: "Buying item...", status: true });
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement),
                billing_details: {
                    name: props.userData['name'],
                    email: props.userData.email,
                    // address: props.userData.address,
                    phone: props.userData.phone,
                },
            })
            if (!error) {
                const { id } = paymentMethod
                props.buyItem({ saleId, amount: salePrice, id, currency: selectedCurrency });
            } else {
                props.toggleLoader({ message: "Buying item...", status: false });
                return EventBus.publish("error", error.message);
            }
        } catch (error) {
            props.toggleLoader({ message: "Buying item...", status: false });
            return EventBus.publish("error", error.message);
        }
    }

    return (
        <div className="wrapper">
            <Navbar />
            <section class="inner-page-banner">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <h1>Payment Method</h1>
                            <ul className="bredcamb">
                                <li><Link className="bredcamb-link" to="/">Home</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link" to="/Buy">Buy</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link active" to="#">Payment Method</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className='payment-method-page'>
                <div className='auto-container'>
                    <div className='row'>
                        <div className='col-lg-10 offset-lg-1 col-md-12'>
                            <ValidatorForm className='form'>
                                <div className='row'>
                                    <div className='col-lg-7 col-md-12'>
                                        <div className='left-content-area'>
                                            <div className='form-block'>
                                                <h3>Shipping Address</h3>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Name </label>
                                                    <TextValidator
                                                        type='text'
                                                        id='name'
                                                        name='name'
                                                        placeholder='Enter your full name'
                                                        value={props?.userData?.name}
                                                        disabled
                                                        className='form-control'
                                                        // onChange={handleChange}
                                                        // validators={['required']}
                                                        errorMessages={['Name is required']}
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='email'>Email </label>
                                                    <TextValidator
                                                        type='email'
                                                        id='email'
                                                        name='email'
                                                        placeholder='Enter your email address here'
                                                        value={props?.userData?.email}
                                                        disabled
                                                        className='form-control'
                                                        // onChange={handleChange}
                                                        // validators={['required']}
                                                        errorMessages={['email is required']}
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='address'>Phone Number</label>
                                                    <TextValidator
                                                        type='text'
                                                        id='address'
                                                        name='address'
                                                        placeholder='Enter you address'
                                                        value={props?.userData?.phone}
                                                        disabled
                                                        className='form-control'
                                                        // onChange={handleChange}
                                                        // validators={['required']}
                                                        errorMessages={['Address is required']}
                                                    />
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='address'>Mailing Address </label>
                                                    <TextValidator
                                                        type='text'
                                                        id='address'
                                                        name='address'
                                                        placeholder='Enter you address'
                                                        value={props?.userData?.address}
                                                        disabled
                                                        className='form-control'
                                                    // onChange={handleChange}
                                                    // validators={['required']}
                                                    // errorMessages={['Address is required']}
                                                    />
                                                </div>
                                                {/* <div className='row'>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor='city'>City </label>
                                                                <TextValidator
                                                                    type='text'
                                                                    id='city'
                                                                    name='city'
                                                                    placeholder=''
                                                                    value={props?.userData?.city}
                                                                    disabled
                                                                    className='form-control'
                                                                    // onChange={handleChange}
                                                                    // validators={['required']}
                                                                    errorMessages={['City is required']}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor='province'>Province </label>
                                                                <TextValidator
                                                                    type='text'
                                                                    id='province'
                                                                    name='province'
                                                                    placeholder=''
                                                                    value={props?.userData?.province}
                                                                    disabled
                                                                    className='form-control'
                                                                    // onChange={handleChange}
                                                                    // validators={['required']}
                                                                    errorMessages={['Province is required']}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor='zipcode'>ZIP Code </label>
                                                                <TextValidator
                                                                    type='number'
                                                                    id='zipcode'
                                                                    name='zipcode'
                                                                    placeholder=''
                                                                    value={props?.userData?.zipcode}
                                                                    disabled
                                                                    className='form-control'
                                                                    // onChange={handleChange}
                                                                    // validators={['required']}
                                                                    errorMessages={['ZIP Code is required']}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-6 col-md-12'>
                                                            <div className='form-group'>
                                                                <label htmlFor='phone'>Country </label>
                                                                <TextValidator
                                                                    type='tel'
                                                                    id='phone'
                                                                    name='phone'
                                                                    placeholder=''
                                                                    value={props?.userData?.country}
                                                                    disabled
                                                                    className='form-control'
                                                                    // onChange={handleChange}
                                                                    // validators={['required']}
                                                                    errorMessages={['Phone Number Code is required']}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                            </div>
                                            <div className='form-block'>
                                                <h3>Payment</h3>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Select Currency </label>
                                                    <select onChange={(e) => selectCurrency(e.target.value)} value={selectedCurrency}>
                                                        <option value="USD">USD</option>
                                                        <option value="HKD">HKD</option>
                                                    </select>
                                                </div>
                                                <div className='form-group'>
                                                    <label htmlFor='name'>Card Number </label>
                                                    <CardNumberElement className="form-control" options={CARD_OPTIONS} />
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <div className='form-group'>
                                                            <label htmlFor='name'>Card Expiry </label>
                                                            <CardExpiryElement className="form-control" options={CARD_OPTIONS} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-12'>
                                                        <div className='form-group'>
                                                            <label htmlFor='name'>Card CVC </label>
                                                            <CardCvcElement className="form-control" options={CARD_OPTIONS} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='form-block'>
                                                <div id="accordion">
                                                    <div class="card">
                                                        <div class="card-header" id="headingTwo">
                                                            <h5 class="mb-0">
                                                                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    Billing Summary
                                                                    <i className='icon'><img src="images/top-arrow.svg" alt="" /></i>
                                                                </button>
                                                            </h5>
                                                        </div>

                                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                                            <div class="card-body">
                                                                <h5>Item Title <span>{saleData['name']}</span></h5>
                                                                <h5>Grand Total <span>{salePrice.toFixed(2)} {selectedCurrency}</span></h5>
                                                                <div className='form-group'>
                                                                    <button className='btn-style-one' onClick={handlePayment}>Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    );
}


const mainFunction = (props) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentMethod {...props} />
        </Elements>
    )

}

const mapDispatchToProps = { toggleLoader, getSaleItemsById, buyItem, itemSold };

const mapStateToProps = ({ Auth }) => {
    let { saleItemById, userData } = Auth;
    return { saleItemById, userData }
};

export default connect(mapStateToProps, mapDispatchToProps)(mainFunction);