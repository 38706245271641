import moment from "moment";
import Slider from "react-slick";
import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


import './index.css';
import '../../static/css/animate.css';
import 'animate.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import validator from 'validator';
import { toggleLoader, setRegisterEmail, getMediaItems } from "../../store/actions/Auth";

const FigureOuch = (props) => {

    const [email, setEmail] = useState(props.registerEmail ? props.registerEmail : "")

    const settingsMain = {
        dots: false,
        nav: false,
        arrows: false,
        autoPlay: true,
        speed: 500,
        slidesToShow: 10,
        responsive: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,

                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    const settingsEv = {
        dots: false,
        nav: true,
        arrows: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 3,
        responsive: true,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    let [editData, setEditData] = useState({});
    let [mediaData, setMediaData] = useState([]);
    let [modalIsOpen, setModalIsOpen] = useState(false);

    const toggle = () => setModalIsOpen(!modalIsOpen);

    const setModalIsOpenToTrue = (item) => {
        setEditData(item);
        setModalIsOpen(true);
    }

    useEffect(() => {
        props.getMediaItems();
    }, []);

    useEffect(() => {
        if (props.mediaItems && props.mediaItems.length > 0) {
            if (props.mediaItems.length >= 9) {
                const first5Elements = props.mediaItems.slice(0, 9);
                setMediaData(first5Elements);
            } else {
                setMediaData(props.mediaItems);
            }
        }
    }, [props.mediaItems]);

    const handleImageError = (idx) => {
        mediaData[idx]['image'] = alternate;
        setMediaData([...mediaData]);
    };

    const editImageError = () => {
        editData['image'] = alternate;
        setEditData([...editData]);
    };

    const gotToregisterPage = () => {
        let isValis = validator.isEmail(email);
        if (!isValis) return EventBus.publish("error", "Email is invalid");
        props.setRegisterEmail(email);
        props.history.push("/SignUp");
    }

    const buySale = () => {
        if (!props.isLogin) return props.history.push("/Login");
        else return props.history.push(`/Buy`);
    }

    return (
        <div className="wrapper">
            <Navbar />
            <section class="banner-area">
                {/* <div class="auto-container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="content">
                                <h1>Figure Out what’s Dream? Figure Ouch!</h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                <div className="register-form">
                                    {!props.isLogin &&
                                        <div className="group-form">
                                            <input type="text" className="control-input" placeholder="Enter your email" onChange={e => setEmail(e.target.value)} value={email} />
                                            <button className="btn-style-one" onClick={gotToregisterPage}>Register now</button>
                                        </div>
                                    }
                                    <p>Enter your email to register yourself for a free concert.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div className="img-area">
                                <img src="images/banner-img.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div dangerouslySetInnerHTML={{
                                __html: `
                                <video loop="true" autoPlay="true" muted="false" preload="auto" controls="false" webkit-playsinline="true" playsinline="true">
                                    <source src="videos/intro.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            `}}></div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="counter-sec">
                <div class="auto-container">
                    <div class="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="title-area">
                                <h4>We speak with <br />our powerful <br />statistics</h4>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="counter-inner">
                                <div className='counter-start' >
                                    <h1 className='CountUp'>10M+</h1>
                                    <p>Active Listeners</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="counter-inner">
                                <div className='counter-start' >
                                    <h1 className='CountUp'>5K+</h1>
                                    <p>Registrations</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="counter-inner last-counter">
                                <div className='counter-start' >
                                    <h1 className='CountUp'>200+</h1>
                                    <p>Concerts</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="events-sec">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="title-area">
                                <div className="sec-title text-left">
                                    <h2>Checkout our Recent Events</h2>
                                </div>
                                <Link className="view-all" to="/Gallery">View All</Link>
                            </div>
                            <Slider {...settingsEv}>
                                {
                                    mediaData.map((item, idx) => (
                                        <div className="event-item" onClick={() => setModalIsOpenToTrue(item)}>
                                            <div className="img-box">
                                                {
                                                    item['mediaType'] == "image" || item['mediaType'] == "Image" ?
                                                        <img key={idx} src={item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                                                        :
                                                        <video width="320" height="240" controls>
                                                            <source src={editData['image']} type="video/*" />
                                                        </video>
                                                }
                                            </div>
                                            <div className="text-box">
                                                <div className="top-box">
                                                    <span>{item['updatedAt'] ? moment.utc(new Date(item['updatedAt']).getTime()).local().format("dddd, MMMM Do YYYY") : "-/-/-"}</span>
                                                </div>
                                                <h3>{item['name'] ? item['name'] : "-"}</h3>
                                                <p>{item['description'] ? item['description'] : "-"}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>

                    </div>
                </div>
            </section>
            <section class="concert-sec">
                <div class="auto-container">
                    <div class="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="title-area">
                                <h4>Want A Free Concert?</h4>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="btn-area">
                                <button className="btn-style-two" onClick={buySale}>Buy Ticket</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="upcoming-sec upcoming-home">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <p>Up Coming Event</p>
                                <h2>Don’t miss our Upcoming Event</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="event-item">
                                <div className="img-box">
                                    <img src="images/upcoming-event.jpeg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="event-item">
                                <div className="text-box">
                                    <div className="top-box">
                                        <span>13 November, 2023</span>
                                        <span>Zurich, Switzerland</span>
                                    </div>
                                    <h3>Figure Ouch</h3>
                                    <p>Figure Ouch upcoming event ticket!</p>
                                    {/* <span className="price">Ticket Price <i className="full-price">50 USD</i> <i className="discount-price">25 USD</i></span> */}
                                    <button className="btn-style-one" onClick={buySale}>Buy Ticket</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section class="partners-sec">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <h2>Our Partners</h2>
                            </div>
                            <Slider {...settingsMain}>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-2.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-4.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-5.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-6.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-2.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-4.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-2.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-4.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-5.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-6.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-1.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-2.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-3.png" alt="" />
                                    </div>
                                </div>
                                <div className="item ">
                                    <div className="img-box">
                                        <img src="images/partner-4.png" alt="" />
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            <section class="vision-sec">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <p>Our Vision</p>
                                <h2>Figure Out what’s Dream? Figure Ouch!</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="text-box">
                                <h3>Mission</h3>
                                <p>Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. Lorem Ipsum is silyeii dummy text of the printing. Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. Ipsum is simply dummy text of the printiiig and typesetting industry.  Ipsum is simply dummy text of the printiiig and typesetting industry.</p>

                                <h3>Vision</h3>
                                <p>Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. Lorem Ipsum is silyeii dummy text of the printing. Lorem Ipsum is simply dummy text of the printiiig and typesetting industry. Ipsum is simply dummy text of the printiiig and typesetting industry.  Ipsum is simply dummy text of the printiiig and typesetting industry.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="img-box">
                                <img src="images/vision-img.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer />

            {/* ---------------Gallery MODAL--------------- */}

            <Modal
                isOpen={modalIsOpen} toggle={toggle}
                className='main-modal add-media-modal'
            >
                <ModalBody className='modal-body'>
                    {
                        Object.keys(editData).length > 0 &&
                        <div className="event-item">
                            <div className="img-box">
                                {
                                    editData['mediaType'] == "image" || editData['mediaType'] == "Image" ?
                                        <img src={editData['image'] ? editData['image'] : alternate} onError={() => editImageError()} alt="Alternate Image" />
                                        :
                                        <video width="320" height="240" controls>
                                            <source src={editData['image']} type="video/*" />
                                        </video>
                                }
                            </div>
                            <div className="text-box">
                                <h3>{editData['name'] ? editData['name'] : "-"}</h3>
                                <p>{editData['description'] ? editData['description'] : "-"}</p>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </div >
    );
}

const mapDispatchToProps = { toggleLoader, setRegisterEmail, getMediaItems };

const mapStateToProps = ({ Auth }) => {
    let { registerEmail, mediaItems, isLogin } = Auth;
    return { registerEmail, mediaItems, isLogin }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FigureOuch));