import { connect } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import './index.css';
import '../../static/css/animate.css';
import 'animate.css';
import '../../static/css/animate.css';
import { web3 } from '../../store/web3';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader, getSaleItems } from "../../store/actions/Auth";

const Buy = (props) => {

    let [saleData, setSaleData] = useState([]);

    useEffect(() => {
        props.toggleLoader({message:"Load sales items", status:true});
        props.getSaleItems();
    },[]);

    useEffect(() => {
        if (props.saleItems && props.saleItems.length > 0) {
            setSaleData(props.saleItems);
        }
    }, [props.saleItems]);

    const handleImageError = (idx) => {
        saleData[idx]['image'] = alternate;
        setSaleData([...saleData]);
    };

    const buySale =(itemId)=> {
        if(!props.isLogin) return props.history.push("/Login");
        else return props.history.push(`/PaymentMethod/${itemId}`);
    }

    return (
        <div className="wrapper">
            <Navbar />
            <section class="inner-page-banner">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <h1>Buy</h1>
                            <ul className="bredcamb">
                                <li><Link className="bredcamb-link" to="/">Home</Link></li>
                                <li>/</li>
                                <li><Link className="bredcamb-link active" to="#">Buy</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="events-sec style-two">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-12">
                            <div className="sec-title text-center">
                                <p>Our Prroducts</p>
                                <h2>Checkout our Latest and High Quality Products</h2>
                            </div>
                        </div>
                        {
                            saleData.length > 0 &&
                            saleData.map((item, idx) => (
                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                    <div className="event-item">
                                        <div className="img-box">
                                            <img key={idx} src={item['image'] ? item['image'] : alternate} onError={() => handleImageError(idx)} alt="Alternate Image" />
                                        </div>
                                        <div className="text-box">
                                            <h3>{item['name'] ? item['name'] : "-"}</h3>
                                            <p>{item['description'] ? item['description'] : "-"}</p>
                                            <span className='price'>{item['price'] ? item['price'] : "-"}</span>
                                            <button className='btn-style-one' onClick={()=>buySale(item['_id'])}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </section>
            <Footer />
        </div >
    );
}

const mapDispatchToProps = {
    toggleLoader,
    getSaleItems
};

const mapStateToProps = ({ Auth }) => {
    let { saleItems,isLogin } = Auth;
    return { saleItems,isLogin }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Buy));