import { connect } from "react-redux";
import React, { Component } from "react";
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import EventBus from 'eventing-bus';
import validator from 'validator';
import { toggleLoader, setRegisterUser, setVerifyCode } from "../../store/actions/Auth";
import './index.css';
import { Link, Redirect, withRouter } from "react-router-dom";

class SingUp extends Component {
  constructor(props) {
    super();
    this.state = {
      name:'',
      email: '',
      password: '',
      phone:'',
      address:'',
      conformPassword:'',
      verifyingCode:'',
    };
  };

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handelSignup = (e) => {
    e.preventDefault();
    let {name, email, password, phone, address, conformPassword } = this.state;
    let isValis = validator.isEmail(email);
    if(!isValis) return EventBus.publish("error", "Email is invalid");
    if(!name || name.trim().length === 0) return EventBus.publish("error", "Please enter full name");
    if(!phone || phone.trim().length === 0) return EventBus.publish("error", "Please enter phone");
    if(!address || address.trim().length === 0) return EventBus.publish("error", "Please enter mailing address");
    if(!password || password.trim().length === 0) return EventBus.publish("error", "Password must not be empty");
    if(conformPassword.trim().length === 0) return EventBus.publish("error", "Conform Password must not be empty");
    if(password !== conformPassword) return EventBus.publish("error", "Password not match");
    this.props.toggleLoader({status:true,message:"User register..."});
    this.props.setRegisterUser({name, email, password, phone, address});
  }

  handelVerify = (e) => {
    e.preventDefault();
    let {email, verifyingCode} = this.state;
    let isValis = validator.isEmail(email);
    if(!isValis) return EventBus.publish("error", "Email is invalid");
    if(!verifyingCode) return EventBus.publish("error", "Please enter code");
    this.props.toggleLoader({status:true,message:"User register..."});
    this.props.setVerifyCode({email,verificationCode:verifyingCode});
  }

  componentDidMount(){
    this.setState({email:this.props?.registerEmail? this.props?.registerEmail : ""})
  }

  componentDidUpdate(prevProps,prevState){
    if(prevProps.getVerifyCode !== this.props.getVerifyCode){
      if(this.props.getVerifyCode == true ){
        this.props.history.push("/Login");
      }
    }
  }

  render() {
    let {name, email, password, phone, address, conformPassword, verifyingCode} = this.state;
    return (
      <div className="login-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
                <div className="log-area">
                <Link to="/"> <img src="images/logo.png" alt="" /></Link>
                </div>
            </div>
          </div>
        </div>
        <div className="form-area">
        <div className="container">
          <div className="row">
          {Object.keys(this.props.getRegisterUser).length == 0 ?
            <div className="col-lg-6 offset-lg-3 col-md-12">
              <div className="form-block">
                <div className="title-area">
                  <p className="login-title">Sign Up</p>
                </div>
                <ValidatorForm className="validator-form">
                  <div className='form-group'>
                    <label htmlFor='fullname'>Full Name </label>
                    <TextValidator
                        fullWidth
                        type='text'
                        name='name'
                        value={name}
                        placeholder="Enter your full name here"
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='email'>Email </label>
                    <TextValidator
                        fullWidth
                        type='email'
                        name='email'
                        placeholder="Enter your email"
                        value={email}
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='email'>Phone Number </label>
                    {/* Issue aya ha check karna ha */}
                    <TextValidator
                        fullWidth
                        type='number'
                        name='phone'
                        value={phone}
                        placeholder="Enter your phone number for example +100000000"
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                     
                  </div>
                  <div className='form-group'>
                    <label htmlFor='address'>Address </label>
                    <TextValidator
                        fullWidth
                        type='text'
                        name='address'
                        value={address}
                        placeholder="Enter your complete address"
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='password'>Password </label>
                    <TextValidator
                        fullWidth
                        type='password'
                        name='password'
                        value={password}
                        placeholder="Enter a strong password"
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='password'>Confirm Password </label>
                    <TextValidator
                        fullWidth
                        type='password'
                        name='conformPassword'
                        placeholder="Enter your password again"
                        value={conformPassword}
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>
                  <div className='form-group btn-group'>
                      <button type="Submit" className='btn-style-one' onClick={this.handelSignup}>
                        Register
                      </button>
                  </div>
                  <div className='form-group links-group'>
                      <Link className="frog-pass" to="#"></Link>
                      <p>Already have account? <Link className="req-pass" to="/Login"> Sign In</Link></p>
                  </div>
                </ValidatorForm>
              </div>
            </div>
            :
            <div className="col-lg-6 offset-lg-3 col-md-12">
              <div className="form-block">
                <div className="title-area">
                  <p className="login-title">Sign Up</p>
                </div>
                <ValidatorForm className="validator-form">
                  <div className='form-group'>
                    <label htmlFor='fullname'>Submit Code</label>
                    <TextValidator
                        fullWidth
                        type='text'
                        name='verifyingCode'
                        value={verifyingCode}
                        placeholder="Enter your full name here"
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                      />
                  </div>

                  <div className='form-group btn-group'>
                      <button type="Submit" className='btn-style-one' onClick={this.handelVerify}>
                        Submit Code
                      </button>
                  </div>
                  <div className='form-group links-group'>
                      <p>Already have account? <Link className="req-pass" to="/Login"> Sign In</Link></p>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          }
          </div>
        </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { toggleLoader,setRegisterUser,setVerifyCode };

const mapStateToProps = ({ Auth }) => {
  let { isLogin, getRegisterUser,registerEmail, getVerifyCode } = Auth;
  return { isLogin, getRegisterUser,registerEmail, getVerifyCode };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SingUp));