import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { web3 } from "../store/web3";
import FigureOuch from './FigureOuch';
import Gallery from './Gallery';
import Buy from './Buy';
import Voting from './Voting';
import CastVote from './CastVote';
import PaymentMethod from './PaymentMethod';
import Login from './Login';
import SingUp from './SingUp';
import UpdateProfile from './UpdateProfile';
import Forgot from './Forgot';
import Loader from '../components/loader';
import { networkId, message } from "../store/config";
import { logout, login } from '../store/actions/Auth';

import '../static/css/style.css';

import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";

const hist = createBrowserHistory();
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  };

  componentDidMount() {
    EventBus.on('tokenExpired', () => this.props.logout());
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  };

  render() {

    return (
      <div>
        <Loader />
        <ToastContainer
          closeOnClick
          position="bottom-left"
        />

        <Router history={hist}>
          <Switch>
            <Route exact path='/' component={props => <FigureOuch {...props} />} />
            <Route exact path='/FigureOuch' component={props => <FigureOuch {...props} />} />
            <Route exact path='/Gallery' component={props => <Gallery {...props} />} />
            <Route exact path='/Buy' component={props => <Buy {...props} />} />
            <Route exact path='/Voting' component={props => <Voting {...props} />} />
            <Route exact path='/CastVote/:id' component={props => <CastVote {...props} />} />
            <Route exact path='/PaymentMethod/:saleId' component={props => <PaymentMethod {...props} />} />
            <Route exact path='/Login' component={props => <Login {...props} />} />
            <Route exact path='/SignUp' component={props => <SingUp {...props} />} />
            <Route exact path='/UpdateProfile' component={props => <UpdateProfile {...props} />} />
            <Route exact path='/Forgot' component={props => <Forgot {...props} />} />
          </Switch>
        </Router>

      </div>
    );
  }
}

const mapDispatchToProps = {
  logout, login
};

const mapStateToProps = ({ Auth }) => {
  let { publicAddress } = Auth;
  return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);